import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
const Person = (props) => {
    return React.createElement("div", { className: "person" },
        React.createElement("div", { className: "person-name" },
            props.person.frontmatter.title,
            React.createElement("div", { style: { marginTop: "6px" } },
                React.createElement("img", { alt: "{props.person.frontmatter.title}", src: "/static/img/stan.jpeg", width: "144" }))),
        React.createElement(MDXRenderer, null, props.person.body));
};
export const Index = ({ data }) => {
    const nodes = data.allMdx.nodes;
    return React.createElement(Layout, { current: "person" },
        React.createElement("div", { className: "content-slim" }, nodes.map((value, index) => (React.createElement(Person, { person: value, key: value.id })))));
};
export const query = graphql `
    query PersonMarkdownDocument {
        allMdx(filter: {frontmatter: {type: {eq: "person"}}}) {
            nodes {
                frontmatter {
                    title
                    image
                }
                body
                id
            }
        }
    }`;
export default Index;
